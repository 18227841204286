/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CalculatorPage } from '@resursbank/ya-react-lib';

const LoanCalculator = props => {
    const calculatorEndpointURL =
        props.env === 'prod'
            ? 'https://calculatorservice-cust-yabank-prod.apps.okd.paas.h.bitbit.net/api/calculator/calculateLoan'
            : 'https://calculatorservice-cust-yabank-stage.apps.okd.paas.h.bitbit.net/api/calculator/calculateLoan';

    return (
        <CalculatorPage
            {...props}
            calculatorActiveProps={{
                ...props.calculatorSettings,
                endpointURL: calculatorEndpointURL,
                debounce: 2,
                defaultSelectedIndex: 4,
            }}
        />
    );
};

LoanCalculator.parseProps = atts => {
    return {
        labels: atts.labels,
        calculatorSettings: {
            nominalInterest: atts.nominalInterest,
            settingUpCharge: atts.settingUpCharge,
            invoiceCharge: atts.invoiceCharge,
        },
        showFooter: atts.showFooter,
        applyNowLink: atts.applyNowLink?.url,
        getOfferLink: atts.getOfferLink?.url,
    };
};

LoanCalculator.propTypes = {
    env: PropTypes.string,
};

const mapStateToProps = state => {
    return {
        env: state.info.env,
    };
};

export default connect(mapStateToProps)(LoanCalculator);
